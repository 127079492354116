.ant-modal {
  width: min-content !important;
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.ant-modal .anticon svg {
  width: 30px;
  height: 30px;
  color: $txt;
  margin-top: 20px;
  margin-right: 20px;
}

.ant-modal-close {
  z-index: 30;
}

.ant-modal-close:hover {
  border: 0px;
}

.ant-notification {
  font-family: $txtFamily !important;
  font-size: $small !important;
}
